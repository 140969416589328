<template>
  <div class="content">
    <template>
      <v-card
        :style="
          $vuetify.breakpoint.xs
            ? 'margin: 4px'
            : 'margin: 0 18px 0 18px'
        "
      >
        <base-loading v-if="loading" />
        <div v-if="!loading">
          <crud-list
            v-model="tecnicos"
            :headers="headers"
            :slots="['item.telefones']"
            :actions="actions"
            :show-expand="true"
            :permission="'list_tecnico'"
            :sort-by="['nome']"
            :sort-desc="[false]"
            export-pdf-xlsx
          >
            <template v-slot:expanded-item="{ item }">
              <td
                v-if="item.unidades.length || item.fazendaProdutores.length"
                :colspan="headers.length"
                style="padding-left: 30px; padding-top: 10px;"
              >
                <div style="max-height: 500px;overflow-y: auto;">
                  <div v-if="item.unidades.length">
                    <v-simple-table
                      dense
                      class="mb-5"
                      style="border: 1px solid lightgrey;"
                    >
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th
                              colspan="4"
                              class="black--text font-weight-black"
                            >
                              Unidades de Negócio
                            </th>
                          </tr>
                          <tr>
                            <th>
                              CNPJ
                            </th>
                            <th>
                              Nome Fantasia
                            </th>
                            <th>
                              Razão Social
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="unidades in item.unidades"
                            :key="unidades.nome_fantasia"
                          >
                            <td>{{ unidades.cnpj }}</td>
                            <td>{{ unidades.nome_fantasia }}</td>
                            <td>{{ unidades.razao_social }}</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </div>

                  <div
                    v-if="item.fazendaProdutores.length"
                  >
                    <v-simple-table
                      dense
                      class="mb-5"
                      style="border: 1px solid lightgrey;"
                    >
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th
                              colspan="6"
                              class="black--text font-weight-black"
                            >
                              Produtores / Fazendas: {{ item.fazendaProdutores.length }} - Ativos: {{ item.fazendaProdutores.filter((e) => e.ativo === 1).length }}
                            </th>
                          </tr>
                          <tr>
                            <th>
                              Status
                            </th>
                            <th>
                              Fazenda
                            </th>
                            <th>
                              Matrícula
                            </th>
                            <th>
                              Matr. Clube
                            </th>
                            <th>Produtor</th>
                            <th>Unidade de negócio</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="fazendaProdutor in item.fazendaProdutores"
                            :key="fazendaProdutor.id"
                          >
                            <td>
                              <v-chip
                                v-if="!fazendaProdutor.ativo"
                                color="red lighten-1"
                                class="mb-2 pl-4 pr-4 my-1"
                                x-small
                                text-color="white"
                              >
                                <b>Inativo</b>
                              </v-chip>
                              <v-chip
                                v-if="fazendaProdutor.ativo"
                                color="green lighten-2"
                                class="mb-2 pl-4 pr-4 my-1"
                                x-small
                                text-color="white"
                              >
                                <b>Ativo</b>
                              </v-chip>
                            </td>
                            <td>{{ fazendaProdutor.fazenda.pessoajuridica.razao_social ? `${fazendaProdutor.fazenda.pessoajuridica.razao_social}` : `${fazendaProdutor.fazenda.pessoajuridica.razao_social}` }}</td>
                            <td>{{ fazendaProdutor.cod_produtor_fornecedor }}</td>
                            <td>{{ fazendaProdutor.cod_produtor_cliente }}</td>
                            <td>{{ fazendaProdutor.produtor?.pessoafisica?.nome ? `${fazendaProdutor.produtor.pessoafisica.nome}` : '' }}</td>
                            <td>{{ fazendaProdutor.fazenda?.unidade_negocio.pessoajuridica ? `${fazendaProdutor.fazenda.unidade_negocio.pessoajuridica.nome_fantasia}` : '' }}</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </div>
                </div>
              </td>
            </template>
            <template
              v-slot:btnCadastro
            >
              <v-col
                v-if="$store.getters.hasPermission('inserir_tecnico')"
              >
                <v-btn
                  block
                  class="text-none text-white"
                  color="primary"
                  title="Cadastrar novo técnico"
                  rounded
                  @click="$router.push({ path: 'tecnicos/cadastrar' })"
                >
                  <v-icon
                    dark
                    left
                  >
                    mdi-file-plus
                  </v-icon>
                  Cadastrar
                </v-btn>
              </v-col>
            </template>
            <template v-slot:[`item.telefones`]="{ item }">
              <div
                v-for="(telefone, index) in item.telefones"
                :key="index"
              >
                <span v-if="telefone">
                  {{ `${index + 1}:  ${telefone}` }}
                  <br>
                </span>
              </div>
            </template>
          </crud-list>
        </div>
      </v-card>
    </template>
  </div>
</template>
<script>
  import store from '@/store'
  import TecnicosStore, { BOOTSTRAP, DELETE_TECNICO } from '@/store/modules/tecnicos'
  import { mapState, mapActions, mapGetters } from 'vuex'
  export default {
    components: {
    },
    data () {
      return {
        actions: [
          {
            title: 'Editar técnico',
            color: 'info darken-3',
            click: item => this.editarTecnico(item),
            icon: 'mdi-pencil',
            permission: 'editar_tecnico',
          },
          {
            title: 'Excluir Técnico',
            color: 'red darken-3',
            click: item => this.DELETE_TECNICO(item),
            icon: 'mdi-trash-can-outline',
            permission: 'excluir_tecnico',
          },
        ].filter(action => store.getters.hasPermission(action.permission)),
        headers: [
          { text: '', sortable: false, groupable: false, floatingfilter: false, value: 'data-table-expand' },
          { text: '', sortable: false, groupable: false, floatingfilter: false, value: 'actions', width: '40px' },
          { align: 'left', sortable: true, groupable: false, floatingfilter: true, text: 'CPF', value: 'cpf', width: 'auto', report: true },
          { align: 'left', sortable: true, groupable: false, floatingfilter: true, text: 'Nome', value: 'nome', width: 'auto', report: true },
          { align: 'left', sortable: true, groupable: true, text: 'Papel', value: 'roles.description', width: 'auto' },
          { align: 'left', sortable: true, groupable: true, filterable: true, text: 'Supervisor', value: 'supervisor.nome', width: 'auto' },
          { align: 'left', sortable: true, groupable: false, floatingfilter: true, text: 'RG', value: 'rg', width: 'auto' },
          { align: 'left', sortable: false, groupable: false, floatingfilter: false, text: 'Email', value: 'email', width: 'auto', report: true },
          { align: 'left', sortable: false, groupable: false, floatingfilter: false, text: 'Telefones', value: 'telefones', width: 'auto', report: true, styleXLSX: { type: 'telefone' } },
        ],
      }
    },
    computed: {
      ...mapState('tecnicos', ['loading', 'tecnicos', 'unidades']),
      ...mapGetters(['hasPermission']),
      isNotifica () {
        if (this.$user.get().role === 'gestor') {
          return true
        }
        return false
      },
    },
    async created () {
      if (!this.$store.hasModule('tecnicos')) { this.$store.registerModule('tecnicos', TecnicosStore) }
      this.BOOTSTRAP()
    },
    methods: {
      ...mapActions('tecnicos', [BOOTSTRAP, DELETE_TECNICO]),
      editarTecnico ({ id }) {
        this.$router.push({
          path: `/${this.$user.get().role}/tecnicos/alterar`,
          query: { id },
        })
      },
    },
  }
</script>
